import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import AOS from 'aos';
import Header from '../components/Header';
import Sitemap from '../components/Sitemap';
import SubscribeNewsletter from '../components/SubscribeNewsletter';
import Footer from '../components/Footer';
import LoginForm from '../components/LoginForm';

import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { API_BASE_URL, BASE_URL, DELIVERY_PRICE_PER_KM, FREE_DELIVERY_UPTO_KM, GOOGLE_MAPS_API_KEY, MIN_ORDER_VALUE_FOR_FREE_DELIVERY } from '../config';
import AddDeliveryLocation from '../components/AddDeliveryLocation';
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { clear, removeCoupon, updateCoupon, updateCouponData } from '../reducers/cart';
import { login, logout } from '../reducers/user';
import { notifyError } from '../utils/toastify';
import PromoCodesListPopUp from '../components/PromoCodesListPopUp';

const CheckoutPage = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY
    });

    // dispatch(logout());

    const user = useSelector((state) => state.user.value);

    const cart = useSelector((state) => state.cart.value);

    useEffect(() => {
        AOS.init({
            duration : 1000
        });

        dispatch(removeCoupon());
    }, []);

    useEffect(() => {
        if(isLoaded === true){
            getAndSetCartProducts();
        }
    }, [isLoaded]);

    const [plainCartProducts, setPlainCartProducts] = useState(cart.products);

    const [paymentMode, setPaymentMode] = useState('online');
    const [orderType, setOrderType] = useState('delivery');

    const [promoCodeInput, setPromoCodeInput] = useState("");

    const [showPromoCodeStatusMessage, setShowPromoCodeStatusMessage] = useState(false);
    const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
    const [isPromoCodeError, setIsPromoCodeError] = useState(false);
    const [promoCodeErrorTxt, setPromoCodeErrorTxt] = useState('');
    const [showPromoCodesListPopUp, setShowPromoCodesListPopUp] = useState(false);

    const [isCouponApplied, setIsCouponApplied] = useState(false);

    const [isShowLogin, setIsShowLogin] = useState(user.is_logged_in === false ? true : false);
    const [isShowAddNewLocation, setIsShowAddNewLocation] = useState(user.locations?.length === 0 ? true : false);
    const [isShowCheckoutDetails, setIsShowCheckoutDetails] = useState(user.locations?.length === 0 ? false : true);
    const [deliveryLocations, setDeliveryLocations] = useState(user.locations);
    const [distance, setDistance] = useState(0);

    const [stores, setStores] = useState([]);

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }

    const paymentModeChanged = (e) => {
        setPaymentMode(e.target.value);
    }

    const orderTypeChanged = (e) => {
        setOrderType(e.target.value);
    }

    useEffect(() => {
        getAndSetCartProducts();
    }, [orderType]);

    const displayRazorpay = async () => {

        /* Check Coupon Validity */
        const order_data = await buildOrderData(false, 0, 0);
        initiateRazorpayReq(order_data.costing.final_price);
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }


    const initiateRazorpayReq = async (totalPrice) => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const raz_order_data = {
            amount: totalPrice
        }

        // creating a new order
        const result = await axios.post(`${API_BASE_URL}order/create-razorpay-order`, raz_order_data, config);

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        // Getting the order details back
        const { amount, id: order_id, currency } = result.data;

        const logo = `${BASE_URL}images/logo-header.png`;

        const options = {
            key: "rzp_live_ozpAOHvffue5Rz", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "Blackbox Pizza",
            description: "The Pizza That Never Existed",
            image: logo,
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                }
                const result = await axios.post(`${API_BASE_URL}order/verify-razorpay-payment`, data, config);
                const order_data = await buildOrderData(true, result.data.orderId, result.data.paymentId);
                try{
                    const createOrderReq = await axios.post(`${API_BASE_URL}order/online`, order_data, config);
                    if(createOrderReq.status === 200){
                        dispatch(clear());
                        navigate(`/order/${createOrderReq.data.id}`);
                    }
                }
                catch(error){
                    console.log(error);
                }
            },
            prefill: {
                name: user.name,
                contact: user.phone_number
            },
            notes: {
                address: "Soumya Dey Corporate Office",
            },
            theme: {
                color: "#16121c",
            },
        }

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const getStores = async () => {
        try{
            const getStoreReq = await axios.get(`${API_BASE_URL}store/active`);
            if(getStoreReq.status === 200){
                setStores(getStoreReq.data)
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getStores();
    }, [])

    const createCodOrder = async () => {
        /* Check Coupon Validity */

        const order_data = await buildOrderData(false, 0, 0);
        try{
            const createOrderReq = await axios.post(`${API_BASE_URL}order/online`, order_data, config);
            if(createOrderReq.status === 200){
                dispatch(clear());
                navigate(`/order/${createOrderReq.data.id}`);
            }
        }
        catch(error){
            console.log(error);
        }
    }


    const buildOrderData = async (isPaymentReceived, rzpOrderId, rzpPaymentId) => {
        let primaryLocation = user.locations.find(location => location.is_primary === true);
        const userLocation = `${primaryLocation.latLng.lat}, ${primaryLocation.latLng.lng}`;

        let order_data = {
            store: stores.length != 0 ? stores[0].id : 0,
            costing: {
                base_price: cartPricing.base_price,
                discount: cartPricing.discount,
                discounted_price: cartPricing.discounted_price,
                delivery_price: cartPricing.delivery_charges,
                delivery_discount: 0,
                discounted_delivery_price: cartPricing.delivery_charges,
                tax: cartPricing.tax,
                final_price: cartPricing.final_price,
                coupon_savings: cartPricing.discount
            },
            delivery: {
                delivery_order_id: "delivery_partner_id",
                package_size: "SMALL",
                distance: distance,
                name: primaryLocation.name,
                address: primaryLocation.address,
                landmark: "",
                latlong: {
                    lat: primaryLocation.latLng.lat,
                    lng: primaryLocation.latLng.lng
                }
            },
            customer: user.id,
            delivery_type: orderType === "delivery" ? "DELIVERY" : "TAKEAWAY",
            is_coupon_applied: cart.is_coupon_applied,
            coupon_code: cart.coupon_id,
            products: cartProducts,
            is_paid: isPaymentReceived,
            razorpay_order_id: rzpOrderId,
            razorpay_payment_id: rzpPaymentId
        }

        return order_data
    }


    const getUserDetailsByUserId = async (user_id) => {

        const config = {
            headers: {
                'Authorization': user.access_token
            }
        }

        try{
            const getUserReq = await axios.get(`${API_BASE_URL}customer/${user_id}`, config);
            if(getUserReq.status === 200){
                return getUserReq.data
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    const updatePrimaryLocation = async (e, location_id) => {
        
        if(e.target.checked === false){
            document.getElementById(location_id).checked = false;
        }
        else if(e.target.checked === true){
            const config = {
                headers: {
                    'Authorization': user.access_token
                }
            }
            const data = {
                customer_id: user.id,
                location_id: location_id
            }

            const updateLocationStatus = await axios.post(`${API_BASE_URL}customer/locations/make-primary`, data, config)

            if(updateLocationStatus.status === 200){

                const updated_user_details = await getUserDetailsByUserId(updateLocationStatus.data.customer.id);

                const userData = {
                    id: updated_user_details.id,
                    name: updated_user_details.name,
                    is_phone_number_validated: true,
                    phone_number: updated_user_details.phone_number,
                    access_token: user.access_token,
                    refresh_token: user.refresh_token,
                    orders: updated_user_details.delivered_orders,
                    locations: updated_user_details.locations,
                    is_active: updated_user_details.is_active,
                    is_logged_in: true
                }

                userData.locations.map(location => {
                    if(location.is_primary === false){
                        document.getElementById(location._id).checked = false;
                    }
                    else{
                        document.getElementById(location._id).checked = true;
                    }
                });
                dispatch(login(userData));
            }
            else{
                console.log('Some Error');
            }
        }
        else{
            document.getElementById(location_id).checked = false;
        }
    }

    useEffect(() => {
        if(user.is_logged_in === true){
            console.log(user.locations);
            if(user.locations.length != 0){
                setIsShowCheckoutDetails(true);
                getAndSetCartProducts();
                setDeliveryLocations(user.locations);
            }
            else{
                setIsShowCheckoutDetails(false);
                setIsShowAddNewLocation(true);
                setDeliveryLocations([]);
            }
        }
        else{
            setIsShowLogin(true);
        }
    }, [user]);

    const showAddNewLocation = async () => {
        setIsShowAddNewLocation(true);
        setIsShowCheckoutDetails(false);
    }

    const [cartProducts, setCartProducts] = useState([]);

    const [cartPricing, setCartPricing] = useState({
        base_price: 0,
        discount: 0,
        discounted_price: 0,
        tax: 0,
        delivery_charges: 0,
        final_price: 0,
        tax_percentage: 5,
        min_order_value_for_free_delivery: MIN_ORDER_VALUE_FOR_FREE_DELIVERY
    });

    const [showCartDetails, setShowCartDetails] = useState(false);
    const [showEmptyCart, setShowEmptyCart] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const getAndSetCartProducts = async () => {
        try{
            const getPricingForCart = await axios.post(`${API_BASE_URL}product/public/cart/pricing`, {products: plainCartProducts});
            console.log(getPricingForCart.data);
            setCartProducts(getPricingForCart.data);
            if(getPricingForCart.data.length !== 0){
                setShowCartDetails(true);
                setShowEmptyCart(false);
            }
            else{
                setShowCartDetails(false);
                setShowEmptyCart(true);
            }
        }
        catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        getAndSetCartProducts();
    }, [plainCartProducts]);

    useEffect(() => {
        if(cartProducts.length != 0){
            calculateCartPricing();
        }
        else{
            cartPricing.base_price = 0;
            cartPricing.discount = 0;
            cartPricing.discounted_price = 0;
            cartPricing.tax = 0;
            cartPricing.delivery_charges = 0;
            cartPricing.final_price = 0;
        }
    }, [cartProducts]);

    const calculateCartPricing = async () => {

        let tempCartPricing = {
            base_price: 0,
            discount: 0,
            discounted_price: 0,
            tax: 0,
            delivery_charges: 0,
            final_price: 0,
            tax_percentage: 5
        }

        await Promise.all(cartProducts.map((product) => {
            tempCartPricing.base_price = tempCartPricing.base_price + (product.price * product.quantity);
            tempCartPricing.discount = tempCartPricing.discount + product.discount;
        }));

        tempCartPricing.discount = tempCartPricing.discount;
        tempCartPricing.discounted_price = tempCartPricing.base_price - tempCartPricing.discount;
        tempCartPricing.tax = tempCartPricing.discounted_price * tempCartPricing.tax_percentage / 100;

        let userLocation = {};

        if(user?.locations?.length > 0){
            user.locations.map(location => {
                if(location.is_primary === true){
                    userLocation = location
                }
            });
    
            const storeLocation = '12.974438858420461, 77.49757498809882';
            const userLatLong = `${userLocation.latLng.lat}, ${userLocation.latLng.lng}`;
    
            const userDistance = await getUserDistance(storeLocation, userLatLong);
    
            const deliveryDistance = userDistance.distance;
            const deliveryDuration = userDistance.duration;

            setDistance(deliveryDistance);
    
            if(orderType == "delivery"){
                if(tempCartPricing.discounted_price >= MIN_ORDER_VALUE_FOR_FREE_DELIVERY){
                    if(deliveryDistance > FREE_DELIVERY_UPTO_KM){
                        let chargableDistance = deliveryDistance - FREE_DELIVERY_UPTO_KM;
                        tempCartPricing.delivery_charges = DELIVERY_PRICE_PER_KM * chargableDistance;
                    }
                    else{
                        tempCartPricing.delivery_charges = 0;
                    }
                }
                else {
                    let chargableDistance = deliveryDistance;
                    tempCartPricing.delivery_charges = DELIVERY_PRICE_PER_KM * chargableDistance;
                }
            }
            else{
                tempCartPricing.delivery_charges = 0;
            }
        }
        else{
            tempCartPricing.delivery_charges = 0;
        }

        tempCartPricing.final_price = tempCartPricing.discounted_price + tempCartPricing.tax + tempCartPricing.delivery_charges;

        setCartPricing({
            base_price: tempCartPricing.base_price,
            discount: tempCartPricing.discount,
            discounted_price: tempCartPricing.discounted_price,
            tax: tempCartPricing.tax,
            tax_percentage: tempCartPricing.tax_percentage,
            delivery_charges: tempCartPricing.delivery_charges,
            final_price: tempCartPricing.final_price
        });
    }

    const getUserDistance = async (store, user) => {

        let distance;
        let duration;

        const directionsService = new window.google.maps.DirectionsService();
        const distanceData = await directionsService.route({origin: store, destination: user, travelMode: 'DRIVING'});

        if(distanceData.status === 'OK'){
            const dData = distanceData.routes[0].legs[0];

            distance = Math.floor(dData.distance.value / 1000);
            duration = Math.floor(dData.duration.value / 60);
        }

        return {
            distance: distance,
            duration: duration
        };
    }


    const applyThisPromoCode = async (promoCode) => {

        if(promoCode === ""){
            return
        }
        else{
            const data = {
                cart: cart.products,
                user: user.id,
                code: promoCode
            }
    
            console.log(data);
    
            try{
                const applyPromoCodeReq = await axios.post(`${API_BASE_URL}coupon/public/apply`, data, config);
                console.log(applyPromoCodeReq);
                if(applyPromoCodeReq.status === 200){
                    console.log(applyPromoCodeReq.data);
                    if(applyPromoCodeReq.data.promo_type === "FREEBIE"){
                        console.log(applyPromoCodeReq.data);
                        setCartProducts(applyPromoCodeReq.data.products);
                        dispatch(updateCoupon({
                            is_coupon_applied: true,
                            coupon: promoCode,
                            coupon_id: applyPromoCodeReq.data.id,
                            coupon_savings: applyPromoCodeReq.data.cart_pricing.coupon_savings
                        }));
                        setShowPromoCodeStatusMessage(true);
                        setIsPromoCodeValid(true);
                        setPromoCodeInput(promoCode);
                        setIsPromoCodeError(false);
                        setPromoCodeErrorTxt("");
                    }
                    else{
                        dispatch(updateCoupon({
                            is_coupon_applied: true,
                            coupon: promoCode,
                            coupon_id: applyPromoCodeReq.data.id,
                            coupon_savings: applyPromoCodeReq.data.cart_pricing.coupon_savings
                        }));
                        setShowPromoCodeStatusMessage(true);
                        setIsPromoCodeValid(true);
                        setPromoCodeInput(promoCode);
                        setIsPromoCodeError(false);
                        setPromoCodeErrorTxt("");
    
                        let tempCartPricing = cartPricing;
    
                        tempCartPricing.base_price = applyPromoCodeReq.data.cart_pricing.base_price;
                        tempCartPricing.discount = applyPromoCodeReq.data.cart_pricing.coupon_savings;
                        tempCartPricing.discounted_price = applyPromoCodeReq.data.cart_pricing.discounted_price;
                        tempCartPricing.tax = applyPromoCodeReq.data.cart_pricing.tax;
                        tempCartPricing.delivery_charges = cartPricing.delivery_charges;
                        tempCartPricing.final_price = applyPromoCodeReq.data.cart_pricing.final_price + cartPricing.delivery_charges;
    
                        console.log(tempCartPricing);
    
                        setCartPricing(tempCartPricing);
                    }
                }
                else{
                    setIsPromoCodeValid(false);
                    setShowPromoCodeStatusMessage(false);
                    setIsPromoCodeError(true);
                    setPromoCodeErrorTxt("Promocode not valid for this order.");
                }
            }
            catch(error){
                if(error.response.status === 404){
                    setIsPromoCodeValid(false);
                    setShowPromoCodeStatusMessage(true);
                    setIsPromoCodeError(true);
                    setPromoCodeErrorTxt("Entered promocode not found!")
                }
                else{
                    setIsPromoCodeValid(false);
                    setShowPromoCodeStatusMessage(true);
                    setIsPromoCodeError(true);
                    setPromoCodeErrorTxt("Promocode not valid for this order.")
                }
            }
        }
    }

    const removeThisPromoCode = async () => {
        dispatch(removeCoupon());
        getAndSetCartProducts();
        setShowPromoCodeStatusMessage(false);
        setIsPromoCodeValid(false);
        setPromoCodeInput('');
    }

    const getLoginStatus = (e) => {
        if(e.is_logged_in === true){
            setIsShowLogin(false);
            if(user.locations.length === 0){
                setIsShowCheckoutDetails(false);
                setIsShowAddNewLocation(true);
            }
            else{
                setIsShowAddNewLocation(false);
                getAndSetCartProducts();
                setIsShowCheckoutDetails(true);
            }
        }
    }

    const getAddDeliveryLocationStatus = (e) => {
        if(e.status === true){
            setIsShowAddNewLocation(false);
            setIsShowCheckoutDetails(true);
        }
    }

    return (
        <>
            <Header />
            <Sitemap pageName={'checkout'} pagePath={'checkout'} />
            {
                isShowLogin === true
                ?
                    <div className="checkoutPage">
                        <div className="checkoutPageLoginForm">
                            <LoginForm getLoginStatus={(e) => {getLoginStatus(e)}} />
                        </div>
                    </div>
                :
                isShowAddNewLocation === true
                ?
                    <div className="checkoutPage">
                        <div className="checkoutPageAddLocationForm">
                            <AddDeliveryLocation getAddDeliveryLocationStatus={(e) => {getAddDeliveryLocationStatus(e)}} fromCheckout={isShowAddNewLocation} />
                        </div>
                    </div>
                :
                isShowCheckoutDetails === true
                ?
                    <>
                        <div className="checkoutPage">
                            <div className="checkoutPageInner">
                                <div className="checkoutDetails">
                                    <div className="checkoutDetailsHeadings">
                                        <div className="checkoutDetailsHeading">
                                            <p className="checkoutDetailsHeadingTxt">checkout</p>
                                        </div>
                                        <div className="checkoutDetailsSectionName">
                                            <p className="checkoutDetailsSectionNameTxt">confirm your order!</p>
                                        </div>
                                    </div>

                                    <div className="checkoutProducts">
                                        {
                                            cartProducts.map(product => {
                                                return (
                                                    <div className="checkoutProductSingle" key={product.id}>
                                                        <div className="checkoutProductSingleCover">
                                                            <div className="checkoutProductSingleCoverImage">
                                                                <img src={product.small_image?.url} className="checkoutProductSingleCoverImg" />
                                                            </div>
                                                        </div>
                                                        <div className="checkoutProductSingleNamePriceAction">
                                                            <div className="checkoutProductSingleNamePrice">
                                                                <div className="checkoutProductSingleName">
                                                                    <p className="checkoutProductSingleNameTxt">{product.product.name}</p>
                                                                </div>
                                                                <div className="checkoutProductSinglePriceCategory">
                                                                    <div className="checkoutProductSingleCategory">
                                                                        <p className="checkoutProductSingleCategoryTxt">{product.category.name}</p>
                                                                    </div>
                                                                    <div className="checkoutProductSinglePriceCategorySeperator">
                                                                        <span className="checkoutProductSinglePriceCategorySeperatorTxt">&nbsp;</span>
                                                                    </div>
                                                                    <div className="checkoutProductSinglePrice">
                                                                        <div className="checkoutProductSinglePriceInner">
                                                                            <div className="checkoutProductSinglePriceIcon">
                                                                                <i className="fas fa-rupee-sign checkoutProductSinglePriceIco"></i>
                                                                            </div>
                                                                            <div className="checkoutProductSinglePriceValue">
                                                                                <p className="checkoutProductSinglePriceValueTxt">{product.price}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="checkoutProductSingleAction">
                                                                <div className="checkoutProductSingleActionInner">
                                                                    <div className="checkoutProductSingleActionQty">
                                                                        <div className="checkoutProductSingleActionQtyLable">
                                                                            <p className="checkoutProductSingleActionQtyLableTxt">Quantity:</p>
                                                                        </div>
                                                                        <div className="checkoutProductSingleActionQtyValue">
                                                                            <p className="checkoutProductSingleActionQtyValueTxt">{product.quantity}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="checkoutProductSingleActionTotalPrice">
                                                                        <div className="checkoutProductSingleActionTotalPriceLable">
                                                                            <p className="checkoutProductSingleActionTotalPriceLableTxt">Total Price:</p>
                                                                        </div>
                                                                        <div className="checkoutProductSingleActionTotalPriceValue">
                                                                            <div className="checkoutProductSingleActionTotalPriceValueInner">
                                                                                <div className="checkoutProductSingleActionTotalPriceValueIcon">
                                                                                    <i className="fas fa-rupee-sign checkoutProductSingleActionTotalPriceValueIco"></i>
                                                                                </div>
                                                                                <div className="checkoutProductSingleActionTotalPriceValueText">
                                                                                    <p className="checkoutProductSingleActionTotalPriceValueTxt">{product.quantity * product.price}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <div className="checkoutPromoCodeSection">
                                        <div className="checkoutPromoCodeSectionInner">
                                            <div className="checkoutPromoCodeInputButtons">
                                                <div className="checkoutPromoCodeInput">
                                                    <input type="text" className="checkoutPromoCodeInputTxt" readOnly={cart.is_coupon_applied === true ? true : false} placeholder="Enter Promo Code" value={promoCodeInput} onChange={(e) => setPromoCodeInput(e.target.value)} />
                                                </div>
                                                <div className="checkoutPromoCodeButton">
                                                    {
                                                        cart.is_coupon_applied === false
                                                        ?
                                                        <button className="checkoutPromoCodeButtonTxt" onClick={() => applyThisPromoCode(promoCodeInput, cartPricing)}>apply</button>
                                                        :
                                                        <button className="checkoutPromoCodeButtonTxt" onClick={() => removeThisPromoCode()}>remove</button>
                                                    }
                                                </div>
                                            </div>


                                            {showPromoCodeStatusMessage === true
                                                ?
                                                <div className="checkoutPromoCodeStatusMessages">
                                                    {
                                                        isPromoCodeValid === true
                                                        ?
                                                        <div className="checkoutPromoCodeStatusMessagesValid">
                                                            <div className="checkoutPromoCodeStatusMessagesValidIcon">
                                                                <i className="fas fa-check-circle checkoutPromoCodeStatusMessagesValidIco"></i>
                                                            </div>
                                                            <div className="checkoutPromoCodeStatusMessagesValidLable">
                                                                <p className="checkoutPromoCodeStatusMessagesValidLableTxt">{`${promoCodeInput.toUpperCase()}`}, Successfully Applied.</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                    {
                                                        isPromoCodeError === true
                                                        ?
                                                        <div className="checkoutPromoCodeStatusMessagesInValid">
                                                            <div className="checkoutPromoCodeStatusMessagesInValidIcon">
                                                                <i className="fas fa-exclamation-circle checkoutPromoCodeStatusMessagesInValidIco"></i>
                                                            </div>
                                                            <div className="checkoutPromoCodeStatusMessagesInValidLable">
                                                                <p className="checkoutPromoCodeStatusMessagesInValidLableTxt">{promoCodeErrorTxt}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                                :
                                                <></>
                                            }

                                            {
                                                cart.is_coupon_applied === false 
                                                ?
                                                <div className="checkoutPromoCodeViewAll">
                                                    <div className="checkoutPromoCodeViewAllInner">
                                                        <div className="checkoutPromoCodeViewAllIcon">
                                                            <i className="fas fa-ticket-alt checkoutPromoCodeViewAllIco"></i>
                                                        </div>
                                                        <div className="checkoutPromoCodeViewAllLable" onClick={() => setShowPromoCodesListPopUp(true)}>
                                                            <p className="checkoutPromoCodeViewAllLableTxt">See All Promo Codes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>

                                    <div className="checkoutStats">
                                        <div className="checkoutStatsInner">
                                            <div className="checkoutStatsBasic">
                                                <div className="checkoutStatBasePrice">
                                                    <div className="checkoutStatBasePriceLable">
                                                        <p className="checkoutStatBasePriceLableTxt">total</p>
                                                    </div>
                                                    <div className="checkoutStatBasePriceValue">
                                                        <div className="checkoutStatBasePriceValueIcon">
                                                            <i className="fas fa-rupee-sign checkoutStatBasePriceValueIco"></i>
                                                        </div>
                                                        <div className="checkoutStatBasePriceValueText">
                                                            <p className="checkoutStatBasePriceValueTxt">{cartPricing.base_price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="checkoutStatRegular">
                                                    <div className="checkoutStatRegularLable">
                                                        <p className="checkoutStatRegularLableTxt">discount</p>
                                                    </div>
                                                    <div className="checkoutStatRegularValue">
                                                        <div className="checkoutStatRegularValueIcon">
                                                            <i className="fas fa-rupee-sign checkoutStatRegularValueIco"></i>
                                                        </div>
                                                        <div className="checkoutStatRegularValueText">
                                                            <p className="checkoutStatRegularValueTxt">{cartPricing.discount}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="checkoutStatRegular">
                                                    <div className="checkoutStatRegularLable">
                                                        <p className="checkoutStatRegularLableTxt">tax &amp; charges</p>
                                                    </div>
                                                    <div className="checkoutStatRegularValue">
                                                        <div className="checkoutStatRegularValueIcon">
                                                            <i className="fas fa-rupee-sign checkoutStatRegularValueIco"></i>
                                                        </div>
                                                        <div className="checkoutStatRegularValueText">
                                                            <p className="checkoutStatRegularValueTxt">{cartPricing.tax}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="cartContainerStatRegular">
                                                    <div className="cartContainerStatRegularLable">
                                                        <p className="cartContainerStatRegularLableTxt">delivery charges</p>
                                                    </div>
                                                    <div className="cartContainerStatRegularValue">
                                                        <div className="cartContainerStatRegularValueIcon">
                                                            <i className="fas fa-rupee-sign cartContainerStatRegularValueIco"></i>
                                                        </div>
                                                        <div className="cartContainerStatRegularValueText">
                                                            <p className="cartContainerStatRegularValueTxt">{cartPricing.delivery_charges}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cartContainerStatRegular deliveryChargesNote">
                                                    <p className="cartContainerStatRegularNoteTxt">Delivery charges applicable for orders below {cartPricing.min_order_value_for_free_delivery}rs.</p>
                                                </div>
                                            </div>
                                            <div className="checkoutStatsFinal">
                                                <div className="checkoutStatFinalPrice">
                                                    <div className="checkoutStatFinalPriceLable">
                                                        <p className="checkoutStatFinalPriceLableTxt">grand total</p>
                                                    </div>
                                                    <div className="checkoutStatFinalPriceValue">
                                                        <div className="checkoutStatFinalPriceValueIcon">
                                                            <i className="fas fa-rupee-sign checkoutStatFinalPriceValueIco"></i>
                                                        </div>
                                                        <div className="checkoutStatFinalPriceValueText">
                                                            <p className="checkoutStatFinalPriceValueTxt">{cartPricing.final_price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="checkoutContainerAction">
                                        <div className="cartContainerActionOptions">
                                            <div className="cartContainerActionOptionSingle">
                                                <div className="cartContainerActionOptionSingleLable">
                                                    <p className="cartContainerActionOptionSingleLableTxt">payment mode:</p>
                                                </div>
                                                <div className="cartContainerActionOptionSingleRadio">
                                                    <label className="form-control">
                                                        <input type="radio" name="payment-mode" onChange={(e)=>paymentModeChanged(e)} value="online" defaultChecked={paymentMode === 'online'} />
                                                        Online
                                                    </label>
                                                    <label className="form-control">
                                                        <input type="radio" name="payment-mode" onChange={(e)=>paymentModeChanged(e)} value="cod" />
                                                        Cash on Delivery
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="cartContainerActionOptionSingle">
                                                <div className="cartContainerActionOptionSingleLable">
                                                    <p className="cartContainerActionOptionSingleLableTxt">order type:</p>
                                                </div>
                                                <div className="cartContainerActionOptionSingleRadio">
                                                    <label className="form-control">
                                                        <input type="radio" name="order-type" onChange={(e)=>orderTypeChanged(e)} value="delivery" defaultChecked={orderType === 'delivery'} />
                                                        Home Delivery
                                                    </label>
                                                    <label className="form-control">
                                                        <input type="radio" name="order-type" onChange={(e)=>orderTypeChanged(e)} value="takeaway" />
                                                        Takeaway
                                                    </label>
                                                </div>
                                            </div>              
                                        </div>

                                        <div className="checkoutContainerActionMain">
                                            <div className="checkoutContainerActionInner">
                                                {
                                                    paymentMode === 'online'
                                                    ?
                                                        <p className="cartContainerActionButton" onClick={displayRazorpay}>continue to payment</p>
                                                    :
                                                        <p className="cartContainerActionButton" onClick={()=> createCodOrder() }>place order</p>
                                                }
                                                
                                                <div className="cartContainerActionEmpty">
                                                    <p className="cartContainerActionEmptyTxt">back to cart</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="checkoutLoginOrDelivery">
                                    <div className="checkoutLoginOrDeliveryMain">
                                        <div className="checkoutLoginSignUp">
                                            <div className="deliveryLocations">
                                                <div className="deliveryLocationsHeading">
                                                    <p className="deliveryLocationsHeadingTxt">my locations</p>
                                                </div>

                                                <div className="deliveryLocationsList">
                                                    {
                                                        user.locations.map(location => {
                                                            return(
                                                                <div className="deliveryLocationSingle" key={location._id}>
                                                                    <div className="deliveryLocationSinglePrimary">
                                                                        <div className="deliveryLocationSinglePrimaryInput">
                                                                            <label className="checkbox">
                                                                                <input type="checkbox" className="checkbox-location" id={location._id} defaultChecked={location.is_primary} disabled={location.is_primary} onChange={(e)=>updatePrimaryLocation(e, location._id)} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="deliveryLocationSingleDetails">
                                                                        <div className="deliveryLocationSingleDetailsName">
                                                                            <p className="deliveryLocationSingleDetailsNameTxt">{location.name}</p>
                                                                        </div>
                                                                        <div className="deliveryLocationSingleDetailsAddress">
                                                                            <p className="deliveryLocationSingleDetailsAddressTxt">{location.address}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                <div className="deliveryLocationsAction">
                                                    <p className="deliveryLocationsActionTxt" onClick={showAddNewLocation}>add new location</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                :
                <></>
            }
            
            {
                showPromoCodesListPopUp === true
                ?
                    <PromoCodesListPopUp closePromoPopUp={(status) => {
                        setShowPromoCodesListPopUp(false);
                    }} />
                :
                    <></>
            }

            <SubscribeNewsletter />
            <Footer />
        </>
    )
}

export default CheckoutPage