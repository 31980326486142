import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from '../reducers/cart';
import { API_BASE_URL } from '../config';
import { notifySuccess } from '../utils/toastify';
import Header from '../components/Header';
import Sitemap from '../components/Sitemap';
import SubscribeNewsletter from '../components/SubscribeNewsletter';
import Footer from '../components/Footer';
import { callAddToCarttAPI, callViewContentAPI } from '../utils/conversionApiEvents';
import CustomizeProductPopUp from '../components/CustomizeProductPopUp';

const MenuPage = () => {

    const user = useSelector((state) => state.user.value);

    useEffect(() => {
        AOS.init({
            duration : 1000
        });
    }, []);

    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart.value);

    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState();

    const [selectedProduct, setSelectedProduct] = useState();

    const [showCustomizePopUp, setShowCustomizePopUp] = useState(false);

    const getCategories = async () => {
        const reqCategories = await axios.get(`${API_BASE_URL}category/public?status=true`);
        
        if(reqCategories.status === 200){
            setCategories(reqCategories.data.items);
        }
        else{
            alert('Cannot get products');
        }
    }

    const checkFirstActiveCategory = () => {
        let categoryId;

        categories.map(thisCategory => {
            if(thisCategory.is_active === true){
                if(!categoryId){
                    categoryId = thisCategory.id; 
                }
                else{
                    return
                }
            }
        });

        if(categoryId != undefined){
            setCategory(categoryId);
            getProducts(categoryId);
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        checkFirstActiveCategory();
    }, [categories]);

    const getProducts = async (cat_id) => {
        const reqProducts = await axios.get(`${API_BASE_URL}product/public/category/${cat_id}?status=true`);
        
        if(reqProducts.status === 200){
            console.log(reqProducts.data);
            setProducts(reqProducts.data);
        }
        else{
            alert('Cannot get products');
        }
    }

    useEffect(() => {
        if(category != undefined){
            getProducts(category);
        }
    }, [category]);

    const addThisProductHandler = (product) => {
        console.log(product);
        setSelectedProduct(product);

        if(product.sizes.length > 0){
            setShowCustomizePopUp(true);
        }
        else if(product.variants.length > 0){
            setShowCustomizePopUp(true);
        }
        else{
            const product_data = {
                id: Date.now(),
                product: product.id,
                category: product.category.id,
                quantity: 1,
                price: product.price.final.store,
                added_using_coupon: false
            }

            dispatch(addProduct(product_data));
            notifySuccess(`${product.name} added to cart!`);
        }
    }
    
    const handleCustomizeProductAction = (status) => {
        if(status === true){
            notifySuccess(`Added to cart!`);
            setShowCustomizePopUp(false);
        }
        else{
            setShowCustomizePopUp(false);
        }
    }

    return (
        <>
            <Header />
            <Sitemap pageName={'menu'} pagePath='menu' />
            <div className="menuPage">
                <div className="menuPageInner">
                    <div className="menuPageHeadings">
                        <div className="menuPageSectionHeading">
                            <p className="menuPageSectionHeadingTxt">menu</p>
                        </div>
                        <div className="menuPageSectionName">
                            <p className="menuPageSectionNameTxt">just choose from the best</p>
                        </div>
                    </div>

                    <div className="menuPageCategoryiesList">
                        <div className="menuPageCategoryProductsMain">
                            {
                                categories.map(thisCategory => {
                                    return(
                                        thisCategory.is_active === true
                                        ?
                                        <div className={thisCategory.id === category ? "menuPageCategoryProductSingle active" : "menuPageCategoryProductSingle"} key={thisCategory.id} onClick={() => setCategory(thisCategory.id)}>
                                            <div className="menuPageCategoryProductSingleIcon">
                                                <i className={thisCategory.icon ? `${thisCategory.icon} menuPageCategoryProductSingleIco` : "menuPageCategoryProductSingleIco"}></i>
                                            </div>
                                            <div className="menuPageCategoryProductSingleLable">
                                                <p className="menuPageCategoryProductSingleLableTxt">{thisCategory.name}</p>
                                            </div>
                                        </div>
                                        :
                                        <span className="hide" key={thisCategory.id}></span>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="menuPageProducts">
                        {
                            products.map(product => {
                                return(
                                    <div className="menuPageProductSingle" key={product.id}>
                                        <div className="menuPageProductSingleCover">
                                            <div className="menuPageProductSingleCoverMain">
                                                <div className="menuPageProductSingleCoverInner">
                                                    <div className="menuPageProductSingleCoverImage">
                                                        <img src={"https://www.blackbox.pizza/api/assets/images/f44a7744-c2f3-4da8-bf22-bf67bae597e2-tandoori-paneer.png"} className="menuPageProductSingleCoverImg" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="menuPageProductSingleDetails">
                                            <div className="menuPageProductSingleDetailsHeading">
                                                <p className="menuPageProductSingleDetailsHeadingTxt">{product.name}</p>
                                            </div>
                                            
                                            <div className="menuPageProductSingleDetailsPrice">
                                                <div className="menuPageProductSingleDetailsPriceMain">
                                                    <div className="menuPageProductSingleDetailsPriceIcon">
                                                        <i className="fas fa-rupee-sign menuPageProductSingleDetailsPriceIco"></i>
                                                    </div>
                                                    
                                                    <div className="menuPageProductSingleDetailsPriceValue">
                                                        <p className="menuPageProductSingleDetailsPriceValueTxt">{product.price.final.online}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="menuPageProductSingleDetailsAction">
                                                <p className="menuPageProductSingleDetailsActionTxt" onClick={()=>addThisProductHandler(product)}>add to cart</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <SubscribeNewsletter />
            <Footer />

            {
                showCustomizePopUp ?
                    <CustomizeProductPopUp product={selectedProduct} completeCustomization={(status) => handleCustomizeProductAction(status)} />
                :
                    <></>
            }
        </>
    )
}

export default MenuPage