import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { Link } from 'react-router-dom';
import { addProduct } from '../reducers/cart';
import { useDispatch, useSelector } from 'react-redux';
import { notifySuccess } from '../utils/toastify';
import CustomizeProductPopUp from './CustomizeProductPopUp';

const HomeRecommendedProducts = () => {
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart.value);

    const user = useSelector((state) => state.user.value);

    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState({});
    const [showProductVariantsPopup, setShowProductVariantsPopup] = useState(false);

    const [selectedProduct, setSelectedProduct] = useState();
    const [showCustomizePopUp, setShowCustomizePopUp] = useState(false);

    const getProducts = async () => {
        const reqProducts = await axios.get(`${API_BASE_URL}recommended`);
        console.log(reqProducts);
        if(reqProducts.status === 200){
            setProducts(reqProducts.data.items);
        }
        else{
            alert('Cannot get products');
        }
    }

    useEffect(() => {
        getProducts();
    }, []);

    const addThisProductHandler = (product) => {
        console.log(product);
        setSelectedProduct(product);

        if(product.sizes.length > 0){
            setShowCustomizePopUp(true);
        }
        else if(product.variants.length > 0){
            setShowCustomizePopUp(true);
        }
        else{
            const product_data = {
                id: Date.now(),
                product: product.id,
                category: product.category.id,
                quantity: 1,
                price: product.price.final.store,
                added_using_coupon: false
            }

            dispatch(addProduct(product_data));
        }
    }

    const handleCustomizeProductAction = (status) => {
        setShowCustomizePopUp(false);
        // dispatch(enableReloadOrder());
    }

    return (
        <>
            <div className="homeRecommendations">
                <div className="homeRecommendationsInner">
                    <div className="homeRecommendationsContent">
                        <div className="homeRecommendationsContentHeadings" data-aos="fade-right">
                            <div className="homeRecommendationsContentPageName">
                                <p className="homeRecommendationsContentPageNameTxt">recommended for you</p>
                            </div>
                            <div className="homeRecommendationsContentHeading">
                                <p className="homeRecommendationsContentHeadingTxt">top selling products</p>
                                <div className="homeRecommendationsContentHeadingAction">
                                    <Link to="menu">
                                        <p className="homeRecommendationsContentHeadingActionTxt">all products</p>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="homeRecommendationsContentProducts">
                            {
                                products.map(product => {
                                    return(
                                        <div className="homeRecommendationsContentProductSingle" data-aos="fade-up" key={product.product.id}>
                                            <div className="homeRecommendationsContentProductSingleCover">
                                                <div className="homeRecommendationsContentProductSingleCoverImage">
                                                    <img src={product.product.small_image?.url} className="homeRecommendationsContentProductSingleCoverImg" />
                                                </div>
                                            </div>
                                            <div className="homeRecommendationsContentProductSingleDetails">
                                                <div className="homeRecommendationsContentProductSingleDetailsHeading">
                                                    <p className="homeRecommendationsContentProductSingleDetailsHeadingTxt">{product.product.name}</p>
                                                </div>
                                                
                                                <div className="homeRecommendationsContentProductSingleDetailsPrice">
                                                    <div className="homeRecommendationsContentProductSingleDetailsPriceInner">
                                                        <div className="homeRecommendationsContentProductSingleDetailsPriceIcon">
                                                            <i className="fas fa-rupee-sign homeRecommendationsContentProductSingleDetailsPriceIco"></i>
                                                        </div>
                                                        <div className="homeRecommendationsContentProductSingleDetailsPriceLable">
                                                            <p className="homeRecommendationsContentProductSingleDetailsPriceLableTxt">{product.product.price.final.online}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="homeRecommendationsContentProductSingleAction" onClick={() => addThisProductHandler(product.product)}>
                                                <i className="fas fa-shopping-cart homeRecommendationsContentProductSingleActionIco"></i>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>

                    <div className="homeRecommendationsBanner" data-aos="fade-left">
                        <div className="homeRecommendationsBannerInner">
                            <div className="homeRecommendationsBannerMain">
                                <div className="homeRecommendationsBannerHeading">
                                    <p className="homeRecommendationsBannerHeadingTxt">Delicious yet a healthy Pizza is now just a call away!</p>
                                </div>
                                <div className="homeRecommendationsBannerAction">
                                    <p className="homeRecommendationsBannerActionTxt">order now</p>
                                </div>
                                <div className="homeRecommendationsBannerContacts">
                                    <div className="homeRecommendationsBannerContactSingle">
                                        <p className="homeRecommendationsBannerContactSingleTxt">+91 80 2390 1652</p>
                                    </div>
                                    <div className="homeRecommendationsBannerContactSingle">
                                        <p className="homeRecommendationsBannerContactSingleTxt">+91 99865 80893</p>
                                    </div>
                                </div>
                                <div className="homeRecommendationsBannerCover">
                                    <div className="homeRecommendationsBannerCoverImage">
                                        <img src="/images/home-delivery-banner.png" className="homeRecommendationsBannerCoverImg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showCustomizePopUp ?
                    <CustomizeProductPopUp product={selectedProduct} completeCustomization={(status) => handleCustomizeProductAction(status)} />
                :
                    <></>
            }
        </>
    )
}

export default HomeRecommendedProducts