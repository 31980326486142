import React, { useState, useEffect} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import axios, { CanceledError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { API_BASE_URL } from '../config';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// import ProductVariantsPopup from './ProductVariantsPopup';
// import { addProduct, clear } from '../reducers/cart';
import { notifySuccess } from '../utils/toastify';
import { callAddToCarttAPI } from '../utils/conversionApiEvents';
import CustomizeProductPopUp from '../components/CustomizeProductPopUp';
import { addProduct } from '../reducers/cart';
// SwiperCore.use();

const HomeProductsByCategory = () => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.value);

    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState();
    const [productSwiper, setProductSwiper] = useState();

    const [selectedProduct, setSelectedProduct] = useState();
    const [showCustomizePopUp, setShowCustomizePopUp] = useState(false);

    const getCategories = async () => {
        const reqCategories = await axios.get(`${API_BASE_URL}category/public?status=true`);
        
        if(reqCategories.status === 200){
            setCategories(reqCategories.data.items);
        }
        else{
            alert('Cannot get products');
        }
    }

    const checkFirstActiveCategory = () => {
        let categoryId;

        categories.map(thisCategory => {
            if(thisCategory.is_active === true){
                if(!categoryId){
                    categoryId = thisCategory.id; 
                }
                else{
                    return
                }
            }
        });

        if(categoryId != undefined){
            setCategory(categoryId);
            getProducts(categoryId);
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        checkFirstActiveCategory();
    }, [categories]);

    const getProducts = async (cat_id) => {
        const reqProducts = await axios.get(`${API_BASE_URL}product/public/category/${cat_id}?status=true`);
        
        if(reqProducts.status === 200){
            console.log(reqProducts.data);
            setProducts(reqProducts.data);
        }
        else{
            alert('Cannot get products');
        }
    }

    useEffect(() => {
        if(category != undefined){
            getProducts(category);
            productSwiper.slideTo(0);
        }
    }, [category]);

    const previousButton = () => {
        productSwiper.slidePrev();
    }
    
    const nextButton = () => {
        productSwiper.slideNext();
    }

    const addThisProductHandler = (product) => {
        console.log(product);
        setSelectedProduct(product);

        if(product.sizes.length > 0){
            setShowCustomizePopUp(true);
        }
        else if(product.variants.length > 0){
            setShowCustomizePopUp(true);
        }
        else{
            const product_data = {
                id: Date.now(),
                product: product.id,
                category: product.category.id,
                quantity: 1,
                price: product.price.final.store,
                added_using_coupon: false
            }

            dispatch(addProduct(product_data));
        }
    }
    
    
    
    const handleCustomizeProductAction = (status) => {
        setShowCustomizePopUp(false);
        // dispatch(enableReloadOrder());
    }

    return (
        <>
            <div className="productsContainer">
                <div className="productsContainerInner">
                    <div className="productsContainerPageName" data-aos="fade-up">
                        <p className="productsContainerPageNameTxt">menu</p>
                    </div>
                    <div className="productsContainerSectionHeading" data-aos="fade-up">
                        <p className="productsContainerSectionHeadingTxt">just choose from the best</p>
                    </div>

                    <div className="productsContainerCategories" data-aos="fade-up">
                        <div className="productsContainerCategoriesMain">
                            {
                                categories.map(thisCategory => {
                                    return(
                                        thisCategory.is_active === true
                                        ?
                                        <div className={thisCategory.id === category ? "productsContainerCategorySingle active" : "productsContainerCategorySingle"} key={thisCategory.id} onClick={() => setCategory(thisCategory.id)}>
                                            <div className="productsContainerCategorySingleIcon">
                                                <i className={thisCategory.icon ? `${thisCategory.icon} productsContainerCategorySingleIco` : "productsContainerCategorySingleIco"}></i>
                                            </div>
                                            <div className="productsContainerCategorySingleLable">
                                                <p className="productsContainerCategorySingleLableTxt">{thisCategory.name}</p>
                                            </div>
                                        </div>
                                        :
                                            <></>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="productsListMain" data-aos="fade-up">
                        <Swiper
                            modules={[Autoplay,Pagination,Navigation]}
                            spaceBetween={30}
                            slidesPerView={3}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 20
                                },
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 30
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                                }
                            }}
                            className="productsList swiper"
                            onSwiper={(swiper) => setProductSwiper(swiper)}
                        >
                            <div className="productsListInner">
                                {
                                    products.map(product => {
                                        return(
                                            <SwiperSlide className="productSingle" key={product.id}>
                                                <div className="productSingleOverlay">&nbsp;</div>
                                                <div className="productSingleSliderActionSingleLeft">
                                                    <div className="productSingleSliderActionSingleButton" onClick={previousButton}>
                                                        <i className="fas fa-chevron-left productSingleSliderActionSingleIco"></i>
                                                    </div>
                                                </div>
                                                <div className="productSingleSliderActionSingleRight">
                                                    <div className="productSingleSliderActionSingleButton" onClick={nextButton}>
                                                        <i className="fas fa-chevron-right productSingleSliderActionSingleIco"></i>
                                                    </div>
                                                </div>
                                                
                                                <div className="productSingleContent">
                                                    <div className="productSingleContentHeading">
                                                        <p className="productSingleContentHeadingTxt">{product.name}</p>
                                                    </div>
                                                    
                                                    <div className="productSingleContentDescription">
                                                        <p className="productSingleContentDescriptionTxt">{product.description}</p>
                                                    </div>
                                                   
                                                    <div className="productSingleContentPrice">
                                                        <div className="productSingleContentPriceInner">
                                                            <div className="productSingleContentPriceIcon">
                                                                <i className="fas fa-rupee-sign productSingleContentPriceIco"></i>
                                                            </div>
                                                            <div className="productSingleContentPriceLable">
                                                                <p className="productSingleContentPriceLableTxt">{product.price.final.online}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="productSingleContentAction">
                                                        <p className="productSingleContentActionTxt" onClick={() => addThisProductHandler(product)}>add to cart</p>
                                                    </div>

                                                    <div className="productSingleContentVisual">
                                                        <div className="productSingleContentVisualImage">
                                                        
                                                            <img src="https://www.blackbox.pizza/api/assets/images/f44a7744-c2f3-4da8-bf22-bf67bae597e2-tandoori-paneer.png" className="productSingleContentVisualImg" />
                                                            {/* <img src={product.small_image?.url} className="productSingleContentVisualImg" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>

            {
                showCustomizePopUp ?
                    <CustomizeProductPopUp product={selectedProduct} completeCustomization={(status) => handleCustomizeProductAction(status)} />
                :
                    <></>
            }
        </>
    )
}

export default HomeProductsByCategory